import { ApiPii, InternalApi } from 'utils/Constants'
import axios from 'axios'
import { AdditionalFileTypes } from 'utils/Constants'
import { handleRequestError } from 'utils/functions'
/*
Create headers
*/
function createHeaders() {
  let headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('access_token') || localStorage.getItem('pii.urlToken')}`
  }

  return headers
}

async function blockPIISupplier(client, environment, validationId, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${validationId}/blockSupplier`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    const response = await axios({
      method: 'put',
      url,
      headers
    })

    if (response && response.status === 200) {
      return { success: true, message: response.data.message }
    } else {
      return { success: false, message: '' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function createAccessCode(urlToken, isUpdate = false) {
  const url = `${ApiPii.EndPoint}/accessCode`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  return axios({
    method: 'post',
    url,
    headers,
    data: {
      isUpdateProcess: isUpdate
    }
  })
}

async function encryptAdditionalFile(
  client,
  environment,
  validationId,
  fileData,
  urlToken,
  sessionId,
  fileType = AdditionalFileTypes.ADDITIONAL_FILE
) {
  try {
    console.log('Encrypt additional', { context: fileData })
    const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${validationId}/processAdditionalFile`
    let headers = {
      Authorization: `Bearer ${urlToken}`
    }
    headers['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', fileData)
    formData.append('fileName', fileData)
    formData.append('fileType', fileType)
    formData.append('sessionId', sessionId)

    const response = await axios({
      method: 'post',
      url,
      headers,
      data: formData
    })

    if (response && response.status === 201) {
      return {
        success: true,
        message: response.data.message,
        fileId: response.data.fileId
      }
    } else {
      return { success: false, message: '' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function getPiiValidation(client, environment, validationId) {
  try {
    let response = await axios({
      method: 'get',
      url: `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/validation/${validationId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('pii.urlToken')}`
      }
    })

    // Check response status code
    console.info(`statusCode: ${response.status} for getPiiValidation`)

    // If it's a success response

    return { success: true, data: response.data }
  } catch (error) {
    console.error(error)
    return handleRequestError(error)
  }
}
async function cancelPiiProcess(client, environment, validationId, nameComparisonResult, urlToken) {
  try {
    let url = `${ApiPii.EndPoint}/pii/cancelProcess`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }

    const requestBody = {
      client,
      environment,
      taskId: validationId,
      nameComparisonResult
    }

    console.log('data to send info', { con: requestBody })
    let response = await axios({
      method: 'put',
      url,
      headers,
      data: requestBody
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 500 || error.response.status === 404) {
          throw new Error(error.response.data.message)
        } else {
          console.error(error.response)
          throw new Error(`cancelPiiProcess returned a status code ${error.response.status}`)
        }
      }
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function saveSupplierInformation(
  client,
  environment,
  validationId,
  updatedValidation,
  taxCountry,
  supplierType,
  taxType,
  supplierName,
  tin,
  accountType,
  accountNumber,
  accountHolderName,
  accountHolderNameMismatch,
  bankName,
  swiftCode,
  routingNumber,
  banksCountry,
  ibanNumber,
  customBankFields,
  isTaxManualCompleted,
  isValidated,
  nameMismatch,
  bankResulTransaction,
  taxResultTransaction,
  urlToken
) {
  try {
    let url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${validationId}/supplierData`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    let dataSend = {
      updatedValidation,
      taxCountry,
      supplierType,
      taxType,
      supplierName,
      tin,
      accountType,
      accountNumber,
      accountHolderName,
      accountHolderNameMismatch,
      bankName,
      swiftCode,
      routingNumber,
      banksCountry,
      ibanNumber,
      customBankFields,
      isTaxManualCompleted,
      isValidated,
      nameMismatch,
      bankResulTransaction,
      taxResultTransaction
    }

    let response = await axios({
      method: 'post',
      url,
      headers,
      data: dataSend
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 500 || error.response.status === 404) {
          throw new Error(error.response.data.message)
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`saveSupplierInformation returned a status code ${error.response.status}`)
        }
      }
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function validateAccessCode(accessCode, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/accessCode/validate`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    const response = await axios({
      method: 'post',
      url,
      headers,
      data: {
        accessCode
      }
    }).catch(error => {
      if (error.response) {
        if (error.response.status === 403) {
          throw new Error(error.response.data.message)
        } else {
          console.error(error.response)
          // Throw error if it's not a success response
          throw new Error(`validateAccessCode returned a status code ${error.response.status}`)
        }
      }
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

async function getSuppliersData(client, environment, status) {
  const url = `${ApiPii.EndPoint}/client/${client}/environment/${environment}/suppliersData`
  const headers = createHeaders()
  return axios({
    method: 'get',
    url,
    headers,
    params: { status: `${status}` }
  })
}
async function getTaxNamesAndCountries({ urlToken }) {
  const url = `${InternalApi.EndPoint}/validation/country-strategies`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }

  const response = await axios({
    method: 'get',
    url,
    headers
  })

  const data = { ...response.data }
  const countries = Object.keys(data)

  countries.forEach(country => {
    if (data[country] && data[country].organization) {
      data[country].organization.forEach(item => {
        if (item && item.strategies) {
          delete item.strategies
        }
      })
    }

    if (data[country] && data[country].individual) {
      data[country].individual.forEach(item => {
        if (item && item.strategies) {
          delete item.strategies
        }
      })
    }
  })

  return { countries: countries, taxNames: data }
}
async function updateSupplierEmail(client, environment, validationId, email) {
  const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${validationId}/supplierData`
  const headers = createHeaders()
  return axios({
    method: 'put',
    url,
    headers,
    data: { email }
  })
}
async function savePiiAgreementTerms(client, environment, validationId, agreementVersion, urlToken) {
  const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${validationId}/version/${agreementVersion}/saveAgreement`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  return axios({
    method: 'put',
    url,
    headers
  })
}
async function finalizePiiForm(client, environment, taskId, status, statusDetail, email, latestFileUsed, urlToken, nameComparisonResult = undefined) {
  const url = `${ApiPii.EndPoint}/finalizeForm`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  try {
    let response
    response = await axios({
      method: 'post',
      url,
      headers,
      data: {
        client: client,
        environment: environment,
        taskId: taskId,
        status: status,
        statusDetail: statusDetail,
        email: email,
        latestFile: latestFileUsed,
        nameComparisonResult
      }
    })
    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: ''
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function executeValidation(validationName, client, environment, validationId, attempt, data, urlToken) {
  const url = `${ApiPii.EndPoint}/executeValidation`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  try {
    let response
    response = await axios({
      method: 'post',
      url,
      headers,
      data: {
        validationName,
        client,
        environment,
        validationId,
        attempt,
        singleData: data
      }
    })
    if (response && response.status === 200) {
      return { success: true, data: response.data }
    } else
      return {
        success: false,
        message: 'An error occurred while validating'
      }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function executeBistroValidation({ appId, data, validationType, urlToken }) {
  const url = `${InternalApi.EndPoint}/core/apps/${appId}/transactions`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }
  const response = await axios({
    method: 'post',
    url,
    headers,
    data: data
  })

  return { ...response.data, type: validationType }
}
async function getBistroTransaction({ appId, transactionId, urlToken }) {
  const url = `${InternalApi.EndPoint}/apps/${appId}/core/transactions/${transactionId}/extendedInfo`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }

  const response = await axios({
    method: 'get',
    url,
    headers
  })

  return response.data
}
async function getBistroTransactionStatus({ appId, transactionId, urlToken }) {
  const url = `${InternalApi.EndPoint}/apps/${appId}/core/transactions/${transactionId}`
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${urlToken}`
  }

  const response = await axios({
    method: 'get',
    url,
    headers
  })

  return response.data
}

async function rejectSupplier(client, environment, validationId, message) {
  const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${validationId}/supplier/rejectSupplier`
  const headers = createHeaders()
  return axios({
    method: 'post',
    url,
    headers,
    data: { message }
  })
}
async function processPIIFile(client, environment, validationId, fileData, urlToken, sessionId) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${validationId}/processFile`
    let headers = {
      Authorization: `Bearer ${urlToken}`
    }
    headers['Content-Type'] = 'multipart/form-data'
    const formData = new FormData()
    formData.append('file', fileData)
    formData.append('fileName', fileData)
    formData.append('sessionId', sessionId)

    const response = await axios({
      method: 'post',
      url,
      headers,
      data: formData
    })

    if (response && response.status === 201) {
      return {
        success: true,
        message: response.data.message,
        fileId: response.data.fileId
      }
    } else {
      return { success: false, message: '' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function getFormProcessSatus(fileId, client, environment, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/process/${fileId}`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }

    const response = await axios({
      method: 'get',
      url,
      headers
    })

    if (response && response.status === 200) {
      //order otherValues
      let otherValuesArray = []
      if (response.data.otherValues) {
        otherValuesArray = response.data.otherValues
        otherValuesArray.sort(function(a, b) {
          let key1 = a.key.toUpperCase()
          let key2 = b.key.toUpperCase()

          return key1.localeCompare(key2)
        })
      }
      console.log('otherValues to return', { context: otherValuesArray })
      return {
        success: true,
        message: response.data.message,
        name: response.data.name,
        ssn: response.data.ssn,
        type: response.data.type,
        otherValues: otherValuesArray
      }
    } else {
      return { success: false, message: '' }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function getRelishMessage(appId, typeId, urlToken) {
  try {
    console.log('getting mesg', { appId, typeId })
    //let tenant = getUserTenant()
    const url = `${ApiPii.EndPoint}/pii/apps/${appId}/type/${typeId}/getRelishMessage`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }
    const response = await axios({
      method: 'get',
      url: url,
      headers
    })
    if (response) {
      // If it's a success response
      if (response.status === 200) {
        return { success: true, data: response.data }
      } else {
        return {
          success: false,
          message: 'Something went wrong, try again later'
        }
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.error(error)
    return {
      success: false,
      message: 'Something went wrong, try again later'
    }
  }
}
async function getPiiConfigurations(client, environment, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/getPiiConfigurations`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }

    const response = await axios({
      method: 'get',
      url,
      headers
    })

    if (response && response.status === 200) {
      return {
        success: true,
        data: response.data.piiValidation?.parameters
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function getPiiSectionConfigurations(client, environment, urlToken) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/getPiiConfigurations`
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${urlToken}`
    }

    const response = await axios({
      method: 'get',
      url,
      headers
    })

    if (response && response.status === 200) {
      return { success: true, data: response.data?.piiValidation?.parameters?.sectionConfigurations || undefined }
    } else {
      return {
        success: false,
        message: 'Something went wrong, try again later'
      }
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}
async function getAnswersFromTask(client, environment, taskId) {
  try {
    const url = `${ApiPii.EndPoint}/pii/client/${client}/environment/${environment}/task/${taskId}/getAnswersFromTask`
    const headers = createHeaders()
    const response = await axios({
      method: 'get',
      url,
      headers
    })

    if (response?.status === 200) {
      return response.data
    } else {
      throw new Error('Something went wrong, try again later')
    }
  } catch (error) {
    console.log(error)
    return { success: false, message: error.message }
  }
}

export {
  blockPIISupplier,
  cancelPiiProcess,
  createAccessCode,
  encryptAdditionalFile,
  executeBistroValidation,
  executeValidation,
  finalizePiiForm,
  getAnswersFromTask,
  getBistroTransaction,
  getBistroTransactionStatus,
  getFormProcessSatus,
  getPiiConfigurations,
  getPiiSectionConfigurations,
  getPiiValidation,
  getRelishMessage,
  getSuppliersData,
  getTaxNamesAndCountries,
  processPIIFile,
  rejectSupplier,
  savePiiAgreementTerms,
  saveSupplierInformation,
  updateSupplierEmail,
  validateAccessCode
}
